import TranslationID from "data/locales/id/business.json";
import { ContainerDesktop } from "components/Container";
import { Image } from "components/Image/Image";
import { pushDataLayer } from "lib/gtag";
import { usePopupStoreFormSales } from "modules/Home/popupStore";
import Button from "components/Button";

export const BestCompanies = [
  {
    id: 1,
    name: "AWS",
    image_url:
      "https://staging-static.gokampus.com/course-institutions/AsRWHzve28oQ7YsppqsN8uYvH84V0oG3tEgXaQGK.png",
    // "https://staging-static.gokampus.com/course-institutions/A2trauZewkpTzQUTnv5RTjwnedbZ4m6I2y2TWZxD.png",
  },
  {
    id: 2,
    name: "ESQ",
    image_url:
      "https://s.gokampus.com/course-institutions/IVP2BFrctpg4NsitZCo9S8GVyJ9QPxI14HfPGGX3.png"
  },
  {
    id: 3,
    name: "PrasMul",
    image_url:
      "https://s.gokampus.com/course-institutions/3aTdRP4IGwGrcg4WYhdHB9pDdKBq84hQ4rSiGXDv.png",
  },
  {
    id: 4,
    name: "UPH",
    image_url:
      "https://s.gokampus.com/course-institutions/YtbTat9wquzhf4o7Ez26cGmfswHn3rVkOb6DAd2u.png",
  },
  {
    id: 5,
    name: "Meta",
    image_url: "https://s.gokampus.com/provider/meta_spark.png",
  },
  {
    id: 6,
    name: "Tiktok",
    image_url:
      "https://s.gokampus.com/course-institutions/xFmcLEx9ssCs6M2mRblgspCLckDGhBul77MImhWs.png",
  },
  {
    id: 7,
    name: "Tokopedia",
    image_url:
      "https://s.gokampus.com/course-institutions/6lJBZv7cpaL9dkXqI9eY2J5BUYmWPDm1gGVHWv3U.png",
  },
  {
    id: 8,
    name: "UiPath",
    image_url:
      "https://s.gokampus.com/course-institutions/5EWzRJoYneG0lLI73q0vMhPT5QJmyBN5cfQa6F52.png",
  },
  {
    id: 9,
    name: "James Gwee",
    image_url:
      "https://s.gokampus.com/course-institutions/Fea14Pf04sLxrMMJX4WeIMuygtN2Q1J5xi4Zmc2f.png",
  },
  {
    id: 10,
    name: "Merry Riana",
    image_url: "https://s.gokampus.com/provider/merry_riana_group.png",
  },
  {
    id: 11,
    name: "Laruno (TDW)",
    image_url:
      "https://s.gokampus.com/course-institutions/Wn5HiLGGlGej7dhFHL0DUSCSQeUkuo58iUPX0ibY.png",
  },
  {
    id: 12,
    name: "Mindtera",
    image_url:
      "https://s.gokampus.com/course-institutions/tCpfhoqLExfpD2O92XRxTj35MWEC6RZNENoFHpeo.png",
  },
];

export const HomeInstitutions: React.FC = () => {
  const headingSectionInstitution = TranslationID["HomeInstitution"];

  const { showPopupFormSales } = usePopupStoreFormSales((state) => ({
    showPopupFormSales: state.showPopupFormSales,
  }));

  const onClickImages = () => {
    pushDataLayer({
      event: "cta-partner",
    });
    showPopupFormSales("");
  };

  const onClickSeeMoreInstitution = () => {
    pushDataLayer({
      event: "cta-partner-see-more",
    });
    showPopupFormSales("");
  };

  return (
    <section id="home-institution">
      <ContainerDesktop className="pt-20 pb-52 sm-max:py-36" useAuto>
        <div className="grid gap-36 text-center pt-24">
          <h2 className="text-b1 font-medium text-neutral-80">
            {headingSectionInstitution.title}
          </h2>
          <div className="cursor-pointer" onClick={() => onClickImages()}>
            <div className="grid gap-30 grid-cols-6 lg-max:grid-cols-3 sm-max:!grid-cols-2">
              {BestCompanies.map((institution) => (
                <div
                  key={Math.random()}
                  className="aspect-[2/1] lg-max:aspect-[2/1] py-8 px-4 bg-white shadow-chip_total rounded-8"
                >
                  <Image
                    src={institution.image_url}
                    alt={institution.name}
                    className={`w-full h-full object-contain`}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="relative" onClick={onClickSeeMoreInstitution}>
            <Button variant="secondary_transparant">Lihat Semua</Button>
          </div>
        </div>
      </ContainerDesktop>
    </section>
  );
};
